/** @format */

import { history } from '../../../history';
import AuthUtil from '../../../utility/auth.util';
export const loginWithJWT = (token, profileId) => {
	return (dispatch) => {
		AuthUtil.setJWTToken(token);
		dispatch({
			type: 'LOGIN_WITH_JWT',
			payload: {},
		});
		history.push(`/`);
	};
};
export const loginWithJWTTeam = (token) => {
	return (dispatch) => {
		AuthUtil.setJWTToken(token);
		dispatch({
			type: 'LOGIN_WITH_JWT',
			payload: {},
		});
	};
};

export const logoutWithJWT = (id) => {
	return (dispatch) => {
		AuthUtil.clearJWTToken();
		dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
		history.push(`/login`);
	};
};

export const storeLoggedInUser = (profile) => {
	return (dispatch) => {
		dispatch({ type: 'STORE_LOGGED_IN_USER', payload: { profile } });
	};
};
