/** @format */

class AuthUtil {
	static getHeaders() {
		return {
			authorization: this.getJWTToken(),
		};
	}
	static getProfile() {
		return {
			profile: this.getProfileId(),
		};
	}

	static getJWTToken() {
		return localStorage.getItem('authorization');
	}
	static getProfileId() {
		return localStorage.getItem('profile');
	}
	static clearJWTToken() {
		localStorage.setItem('authorization', '');
		localStorage.setItem('profile', '');
	}

	static setJWTToken(jwtToken, id) {
		this.jwtToken = jwtToken;
		localStorage.setItem('authorization', jwtToken);
		localStorage.setItem('profile', id);
	}
}

export default AuthUtil;
