/** @format */

export const login = (state = { token: '', user: {} }, action) => {
	switch (action.type) {
		case 'LOGIN_WITH_JWT': {
			return { ...state, ...action.payload };
		}
		case 'LOGOUT_WITH_JWT': {
			return { ...state, ...action.payload };
		}

		case 'STORE_LOGGED_IN_USER': {
			return { ...state, ...action.payload };
		}
		default: {
			return state;
		}
	}
};
