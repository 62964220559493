/** @format */

import React from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux';
import { logoutWithJWT, storeLoggedInUser } from '../../../redux/actions/auth/loginActions';
import * as Icon from 'react-feather';

const UserDropdown = (props) => {
	return (
		<DropdownMenu right>
			<DropdownItem tag='a' href='#' onClick={(e) => props.logoutWithJWT()}>
				<Icon.Power size={14} className='mr-50' />
				<span className='align-middle'>Log Out</span>
			</DropdownItem>
		</DropdownMenu>
	);
};

class NavbarUser extends React.PureComponent {
	state = {
		username: '',
	};
	async componentDidMount() {
		// const res = await LoginService.getSelf();
		// if (res.success) {
		// 	this.props.storeLoggedInUser(res.data);
		// 	this.setState({ username: res.data.name });
		// } else {
		// 	this.props.logoutWithJWT();
		// }
	}

	render() {
		return (
			<ul className='nav navbar-nav navbar-nav-user float-right'>
				<UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
					<DropdownToggle tag='a' className='nav-link dropdown-user-link'>
						<div className='user-nav d-sm-flex d-none'>
							<span className='user-name text-bold-600'>{this.state.username}</span>
						</div>
						<span data-tour='user'>
							<Icon.User size={30} className='mr-50' />
						</span>
					</DropdownToggle>
					<UserDropdown {...this.props} />
				</UncontrolledDropdown>
			</ul>
		);
	}
}
export default connect(null, { logoutWithJWT, storeLoggedInUser })(NavbarUser);
